import React from 'react'
import './Categories.scss';

class Categories extends React.Component {
    render () {
        const { categories: usedCategories } = this.props;

        const categories = [
            {
                title: 'Legal & Financial Setup',
                key: 'legal'
            },
            {
                title: 'Office Options',
                key: 'office'
            },
            {
                title: 'Market Penetration & Sales',
                key: 'market'
            },
            {
                title: 'PR & Branding',
                key: 'sales'
            },
            {
                title: 'Local Fundraising',
                key: 'funding'
            },
            {
                title: 'Learnings from Founders',
                key: 'founders'
            },
            {
                title: 'Network, Accelerators & Events',
                key: 'accelerators'
            },
            {
                title: 'Recruiting Local Talent',
                key: 'recruiting'
            }
        ].filter(category => usedCategories.includes(category.key));

        if (!categories.length) {
            return null;
        }

        return (
            <ul className="categories">
                { categories.map((category, i) => (
                    <li key={category.title} className={`category ${category.key}`}>{category.title}</li>
                ))}
            </ul>
        );
    }
}

export default Categories;
