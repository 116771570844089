import React, { Component } from 'react';
import { utils, writeFileXLSX } from 'xlsx';

import './Contacts.scss';

export default class Contacts extends Component {
    exportFile = this.exportFile.bind(this);

    renderImage(value) {
        if (!value.picture) {
            return null;
        }

        return (
            <div className="ImageContainer">
                <img className="ContactImage" src={ `${process.env.REACT_APP_API}${value.picture.large.src}` } alt={ value.picture.title } />
            </div>
        )
    }

    renderLogo(logo) {
        if (!logo) {
            return null;
        }
        return (
            <div className="ImageContainer">
                <img className="LogoImage" src={ `${process.env.REACT_APP_API}${logo.large.src}` } alt={ logo.title }/>
            </div>
        )
    }

    renderLinkedin(value) {
        return (
            <>
                { value.linked_in &&
                    <a className="linkedin" href={ value.linked_in }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"><path fill="#1A3C42" d="M15.3,0 L0.7,0 C0.3,0 0,0.3 0,0.7 L0,15.4 C0,15.7 0.3,16 0.7,16 L15.4,16 C15.8,16 16.1,15.7 16.1,15.3 L16.1,0.7 C16,0.3 15.7,0 15.3,0 Z M4.7,13.6 L2.4,13.6 L2.4,6 L4.8,6 L4.8,13.6 L4.7,13.6 Z M3.6,5 C2.8,5 2.2,4.3 2.2,3.6 C2.2,2.8 2.8,2.2 3.6,2.2 C4.4,2.2 5,2.8 5,3.6 C4.9,4.3 4.3,5 3.6,5 Z M13.6,13.6 L11.2,13.6 L11.2,9.9 C11.2,9 11.2,7.9 10,7.9 C8.8,7.9 8.6,8.9 8.6,9.9 L8.6,13.7 L6.2,13.7 L6.2,6 L8.5,6 L8.5,7 L8.5,7 C8.8,6.4 9.6,5.8 10.7,5.8 C13.1,5.8 13.5,7.4 13.5,9.4 L13.5,13.6 L13.6,13.6 Z"/></svg>
                    </a>
                }
            </>
        )
    }

    exportFile() {
        const { speakers, startups, title } = this.props;
        const speakersData = speakers.map(({
            name, email, company, linked_in
        }) => ({name, email, company, linked_in}));
        const startupsData = startups.map(({
            value: { name, email, linked_in }, name: company
        }) => ({name, email, company, linked_in}));
        const data = [...speakersData, ...startupsData]
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, `${title}-contact-list.xlsx`);
    } 

    render() {
        const { speakers, startups, userRole } = this.props;

        return (
            <div className="ContactsContainer">
                { userRole.includes('admin') && <button className="export" onClick={this.exportFile}>Export Contacts</button>}
                <h2>Program speakers</h2>
                <ul className="ContactsList">
                    { speakers.map(value => (
                        <li className="Contact">
                            { this.renderImage(value) }       
                            <div>                             
                                <p>{ value.name } {this.renderLinkedin(value)}</p>
                                <p>{ value.title }, { value.company }</p>
                                <a href={`mailto:${value.email}`}>{value.email.toLowerCase()}</a>
                            </div>
                        </li>
                    ))}
                </ul>
                <h2>Participating startups</h2>
                <ul className="ContactsList">
                    { startups.map(startup => (
                        <li className="Contact">
                            { this.renderLogo(startup.logo) } 
                            <div>                             
                                <p>{ startup.value.name } {this.renderLinkedin(startup.value)}</p>
                                <p>{ startup.value.title }, { startup.name }</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}
