export const getDay = (date) => {
    let days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];

    const newDate = _formatDateUTC(date);
    let day = newDate.getUTCDay();

    return days[day];
}

export const getMonth = (date) => {
    let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]

    const newDate = _formatDateUTC(date);
    let month = newDate.getUTCMonth()    

    return months[month];
}

export const getYear = (date) => {
    const newDate = _formatDateUTC(date);
    let year = newDate.getUTCFullYear();

    return year;
};

export const englishOrdinalSuffix = (dt) => {
    const newDate = _formatDateUTC(dt);
    return newDate.getUTCDate()+(newDate.getUTCDate() % 10 === 1 && newDate.getUTCDate() !== 11 ? 'st' : (newDate.getUTCDate() % 10 === 2 && newDate.getUTCDate() !== 12 ? 'nd' : (newDate.getUTCDate() % 10 === 3 && newDate.getUTCDate() !== 13 ? 'rd' : 'th')));
}

export const getNextDay = (date, increase = 1) => {
    const dateTwo = _formatDateUTC(date);
    dateTwo.setUTCDate(dateTwo.getUTCDate() + increase);

    return dateTwo;
}

export const getDate = (date) => {
    const newDate = _formatDateUTC(date);
    return newDate.getUTCDate()
}

const _formatDateUTC = (date) => {
    if (typeof date === 'string') {
        const dateArray = date.split('-');
        return new Date(Date.UTC(dateArray[0], dateArray[1] - 1, dateArray[2]));
    }
    
    return date;
}