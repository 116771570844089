import React, { Component } from 'react';
import './Day.scss';

import Session from '../Session/Session';

export default class Day extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSession: null
        }

        this.updateActiveSession = this.updateActiveSession.bind(this);
        this.updateCloseSession = this.updateCloseSession.bind(this);
    }

    updateActiveSession(e, sessionIndex) {

        if (e.target.classList.contains('react-add-to-calendar__button')) return null;
        document.body.classList.add('no-scroll');
        this.setState({
            activeSession: sessionIndex
        })
    }

    updateCloseSession() {
        document.body.classList.remove('no-scroll');
        this.setState({
            activeSession: null
        })
    }

    getTimeZone(id) {
        if (id.includes('london')) {
            return 'Europe/London';
        } else if (id.includes('stockholm')) {
            return 'Europe/Stockholm';
        } else if (id.includes('amsterdam')) {
            return 'Europe/Amsterdam';
        } else if (id.includes('NY')) {
            return 'America/New_York';
        } else if (id.includes('berlin')) {
            return 'Europe/Amsterdam';
        }
    }

    render() {
        const {
            id,
            day: {
                value: {
                    sessions
                }
            }
        } = this.props;

        const timezone = this.getTimeZone(id);
        
        return (
            <div className="Day">
                { sessions.map((session, index) => (
                    <React.Fragment key={ `${session.value.title}day${index}` }>
                        <Session timezone={timezone} session={ session } handleSessionClick={ this.updateActiveSession } index={ index } sessions={ sessions } />
                    </React.Fragment>
                )) }
            </div>
        )
    }
}
