export const getPlaybooks = () =>
	fetch(`${process.env.REACT_APP_API}/api/v2/pages/?type=playbook.Playbook&fields=slug,playbook_title,playbook_header_image,date_from,date_to`)
	  .then((response) => {
	      return response.json()
	  });


export const requestPlaybook = (id) =>
	fetch(`${process.env.REACT_APP_API}/api/v2/pages/?type=playbook.Playbook&slug=${id}&fields=playbook_title,playbook_header_image,playbook_description,date_from,date_to,day,startups,playbook_additional_logos`)
	  .then((response) => {
	      return response.json()
	  });
