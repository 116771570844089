import React, { Component, createRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import AddToCalendar from '../AddToCalendar/AddToCalendar';
import SessionDetails from '../SessionDetails/SessionDetails';
import './Session.scss';

export default class Session extends Component {
    constructor(props) {
        super(props)
        this.state = {
            areDetailsActive: false
        }

        this.sessionDetailsRef = createRef();
    }

    handleSessionClick() {
        const { areDetailsActive } = this.state;
        const children = this.sessionDetailsRef.current.children
        const el = children[children.length - 1];
        
        if (el.style.maxHeight) {
            el.style.maxHeight = null;
        } else {
            el.style.maxHeight = el.scrollHeight + "px";
        } 

        this.setState({
            areDetailsActive: !areDetailsActive
        })
    }

    renderSpeakers() {
        const {
            session: {
                value: {
                    speakers
                }
            }
        } = this.props;

        return (
            <p className="speakers">
                <b>{`Speakers: `}</b>
                { speakers.map((speaker, index) => (
                    <React.Fragment key={`${speaker.value.name}${index}`}>
                        { speaker.value.name }
                        { speaker.value.name && (index !== speakers.length && !speaker.value.company) - 1 && ', '}
                        { speaker.value.company }
                        { speaker.value.company && index !== speakers.length - 1 && '  |  ' }
                    </React.Fragment>
                ))}
            </p>
        )
    }

    buttonLabel() {
        return (
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="36.447px" height="36.447px" viewBox="0 0 36.447 36.447" style={{enableBackground: "new 0 0 36.447 36.447"}}>
            <g>
                <g>
                    <path d="M30.224,3.948h-1.098V2.75c0-1.517-1.197-2.75-2.67-2.75c-1.474,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75
                        c0-1.517-1.197-2.75-2.67-2.75c-1.473,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75
                        c-1.473,0-2.67,1.233-2.67,2.75v1.197H6.224c-2.343,0-4.25,1.907-4.25,4.25v24c0,2.343,1.907,4.25,4.25,4.25h24
                        c2.344,0,4.25-1.907,4.25-4.25v-24C34.474,5.855,32.567,3.948,30.224,3.948z M25.286,2.75c0-0.689,0.525-1.25,1.17-1.25
                        c0.646,0,1.17,0.561,1.17,1.25v4.896c0,0.689-0.524,1.25-1.17,1.25c-0.645,0-1.17-0.561-1.17-1.25V2.75z M17.206,2.75
                        c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M9.125,2.75
                        c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z
                         M31.974,32.198c0,0.965-0.785,1.75-1.75,1.75h-24c-0.965,0-1.75-0.785-1.75-1.75v-22h27.5V32.198z"/>
                    <rect x="6.724" y="14.626" width="4.595" height="4.089"/>
                    <rect x="12.857" y="14.626" width="4.596" height="4.089"/>
                    <rect x="18.995" y="14.626" width="4.595" height="4.089"/>
                    <rect x="25.128" y="14.626" width="4.596" height="4.089"/>
                    <rect x="6.724" y="20.084" width="4.595" height="4.086"/>
                    <rect x="12.857" y="20.084" width="4.596" height="4.086"/>
                    <rect x="18.995" y="20.084" width="4.595" height="4.086"/>
                    <rect x="25.128" y="20.084" width="4.596" height="4.086"/>
                    <rect x="6.724" y="25.54" width="4.595" height="4.086"/>
                    <rect x="12.857" y="25.54" width="4.596" height="4.086"/>
                    <rect x="18.995" y="25.54" width="4.595" height="4.086"/>
                    <rect x="25.128" y="25.54" width="4.596" height="4.086"/>
                </g>
            </g>
            </svg>
        )
    }

    render() {
        const { areDetailsActive } = this.state;
        const {
            session: {
                value: {
                    title,
                    description,
                    time,
                    end_time,
                    category,
                    location,
                    speakers,
                    video_link_text,
                    video_link
                }
            },
            session,
            timezone
        } = this.props;

        const timeString = time && time.split('T')[1].split('Z')[0];
        const endTimeString = end_time && end_time.split('T')[1].split('Z')[0];

        const formattedTime = timeString && `${timeString.split(':')[0]}:${timeString.split(':')[1]}`;
        const formattedEndTime = endTimeString && `${endTimeString.split(':')[0]}:${endTimeString.split(':')[1]}`;

        const event = {
            title: title,
            description: description,
            location: location,
        };
        
        const getOffset = (timeZone = 'UTC', date = new Date()) => {
            const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
            const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
            return (tzDate.getTime() - utcDate.getTime()) / 6e4;
        }

        const timezoneOffset = getOffset(timezone)/60;
        const formattedTimezone = Math.sign(timezoneOffset) === 1 ? `+0${timezoneOffset}:00` : `-0${Math.abs(timezoneOffset)}:00`;

        if (time) {
            event['startTime'] = time.split('Z')[0];
        }

        if (end_time) {
            event['endTime'] = end_time.split('Z')[0];
        }

        return (
            <div className={`session ${category.toString().replace(',', ' ')}`} ref={this.sessionDetailsRef}>
                <div className="time">
                    <h3>{ formattedTime }{formattedEndTime && ` - ${formattedEndTime}`}</h3>
                </div>
                <div className="details">
                    <h3>
                        { title }
                        { category.map((cat, i) => (
                            <span key={cat + i} className={ `category ${cat}` } />
                        )) }
                    </h3>
                    <div className="description">
                        { ReactHtmlParser(description)}
                    </div>
                    { speakers.length > 0 && this.renderSpeakers() }
                    { location &&
                        <a className="location" href={`https://maps.google.com/?q=${location}`} target="blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19"><g fill="none" fillRule="evenodd" stroke="#1A3C42" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(1 1)"><path d="M13,6.47619048 C13,10.4428571 6.5,17 6.5,17 C6.5,17 0,10.4428571 0,6.47619048 C0,2.34761905 3.33125,0 6.5,0 C9.66875,0 13,2.34761905 13,6.47619048 Z"/><ellipse cx="6.5" cy="6.476" rx="2.438" ry="2.429"/></g></svg>
                            { location }
                        </a>
                    }
                    { video_link &&
                        <a className="videolink" href={ video_link } target="blank">
                        <svg height="472pt" viewBox="0 -87 472 472" width="472pt" xmlns="http://www.w3.org/2000/svg"><path d="m467.101562 26.527344c-3.039062-1.800782-6.796874-1.871094-9.898437-.179688l-108.296875 59.132813v-35.480469c-.03125-27.601562-22.398438-49.96875-50-50h-248.90625c-27.601562.03125-49.96875 22.398438-50 50v197.421875c.03125 27.601563 22.398438 49.96875 50 50h248.90625c27.601562-.03125 49.96875-22.398437 50-50v-34.835937l108.300781 59.132812c3.097657 1.691406 6.859375 1.625 9.894531-.175781 3.039063-1.804688 4.898438-5.074219 4.898438-8.601563v-227.816406c0-3.53125-1.863281-6.796875-4.898438-8.597656zm-138.203124 220.898437c-.015626 16.5625-13.4375 29.980469-30 30h-248.898438c-16.5625-.019531-29.980469-13.4375-30-30v-197.425781c.019531-16.558594 13.4375-29.980469 30-30h248.90625c16.558594.019531 29.980469 13.441406 30 30zm123.101562-1.335937-103.09375-56.289063v-81.535156l103.09375-56.285156zm0 0" /></svg>
                            { video_link_text || video_link }
                        </a>
                    }
                </div>

                <div className="readmore">
                    <AddToCalendar
                        event={ event }
                        timezone={ timezone }
                    />
                    { speakers.length > 0 && <p onClick={ () => this.handleSessionClick() }><img className={`arrowIcon ${areDetailsActive && 'arrowIcon-active'}`} src="/assets/images/Arrow-back-red.svg" alt="Arrow back icon" /> { areDetailsActive ? 'Collapse details' : 'Expand details'}</p> }
                </div>
                { speakers.length > 0 && <SessionDetails activeSession={ session } areDetailsActive={ areDetailsActive } />}
                
            </div>
        )
    }
}
