import React, { Component } from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
import ProgramDate from '../ProgramDate/ProgramDate';

export default class Header extends Component {
    render() {
        const {
            data: {
                playbook_additional_logos,
                playbook_title,
                playbook_header_image: {
                    title,
                    meta: {
                        download_url
                    }
                },
                date_from,
                date_to
            }
        } = this.props;

        return (
            <div className="Header">
                <div className="Header-left">
                    <Link to="/"><img src="/assets/images/arrow-right.svg" alt="Arrow right icon"/></Link>
                    <div className="additionalLogos">
                        { playbook_additional_logos.map(logo => <img src={`${process.env.REACT_APP_API}${logo.value.large.src}`} alt={logo.value.large.alt} />) }
                    </div>
                    <h1>{ playbook_title} </h1>
                    <ProgramDate dateFrom={ date_from } dateTo={ date_to } />
                </div>
                <div className="Header-right">
                    <img src={`${process.env.REACT_APP_API}${download_url}`} alt={ title } />
                </div>
            </div>
        );
    }
}
