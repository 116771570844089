import React from 'react';
import { getPlaybooks } from '../../Request.js'

import CityCard from '../../components/CityCard/CityCard'
import Loader from '../../components/Loader/Loader';

import './Home.scss'

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }
    componentDidMount() {
        getPlaybooks().then((data) => {
            this.setState({
                isLoading: false,
                data: data.items
            });
        })
    }
    render () {
        const { isLoading, data } = this.state;
        
        if (isLoading) return <Loader />;

        data.sort((a,b) => new Date(b.date_from) - new Date(a.date_from));

        return (
            <div className="home">
                <h1>Active Programs</h1>
                <div className="home-programs">
                    {
                        data.map((playbook) => (
                            <CityCard playbook={ playbook } />
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default Home;
