import React from 'react';
import firebase from 'firebase';
import { getPlaybooks } from '../../Request.js'

import Loader from '../../components/Loader/Loader';

import './Admin.scss';

class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            docs: [],
            isAdmin: false
        }
    }
    componentDidMount() {
        const { userRole } = this.props;

        if (userRole && userRole.includes('admin')) {
            this.setState({
                isAdmin: true
            })
        }

        if (userRole && userRole.includes('admin')) {
            const db = firebase.firestore();
            const collection = db.collection("usersCollection");
            collection.get().then((docs) => {
                const docsArray = [];
                docs.forEach(doc => {
                    const {
                        role, 
                        name,
                        email
                    } = doc.data();
                    
                    docsArray.push({ 
                        roles: role && role.split(','), 
                        name,
                        email, 
                        id: doc.id 
                    } )
                });
                getPlaybooks().then((data) => {
                    this.setState({
                        docs: docsArray,
                        isLoading: false,
                        data: data.items
                    });
                })
                
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { userRole } = this.props;
        const { userRole: prevRole } = prevProps;

        if (userRole && userRole !== prevRole && userRole.includes('admin')) {
            this.setState({
                isAdmin: true
            })
        }

        if (userRole && userRole !== prevRole && userRole.includes('admin')) {
            const db = firebase.firestore();
            const collection = db.collection("usersCollection");
            collection.get().then((docs) => {
                const docsArray = [];
                docs.forEach(doc => {
                    const {
                        role, 
                        name,
                        email
                    } = doc.data();
                    
                    docsArray.push({ 
                        roles: role && role.split(','), 
                        name,
                        email, 
                        id: doc.id 
                    } )
                });
                getPlaybooks().then((data) => {
                    this.setState({
                        docs: docsArray,
                        isLoading: false,
                        data: data.items
                    });
                })
                
            })
        }
    }

    handleChange(value, i) {
        const { docs } = this.state;

        const editItem = docs[i];
        const roleIndex = editItem.roles.indexOf(value);

        if (roleIndex !== -1) {
            editItem.roles.splice(roleIndex, 1);
        } else {
            editItem.roles.push(value);
        }

        const newDocs = docs;

        newDocs.splice(i, 1, editItem);

        this.setState({
            docs: newDocs
        });

        const db = firebase.firestore();
        const collection = db.collection("usersCollection");
        const {
            name,
            email,
            roles
        } = editItem;

        const data = {
            name,
            email,
            role: roles.join(',')
        };
        collection.doc(editItem.id).set(data);
    } 

    renderDoc(doc, i) {
        const { data } = this.state;

        return (
            <li className="user">
                <span className="name">{ doc.name }</span>
                <span className="email">{ doc.email }</span>
                <span className="roles">
                    <>
                        { data.map(playbook => (
                            <label>
                                <input 
                                    type="checkbox" 
                                    value={ playbook.meta.slug } 
                                    checked={ doc.roles && doc.roles.includes(playbook.meta.slug)} 
                                    onChange={ (e) => this.handleChange(e.target.value, i) }
                                />
                                { playbook.meta.slug.toUpperCase() }
                            </label>
                        ))}
                        <label>
                            <input 
                                type="checkbox" 
                                value="admin" 
                                checked={ doc.roles && doc.roles.includes('admin')} 
                                onChange={ () => this.handleChange('admin', i) }
                            />
                            ADMIN
                        </label>
                    </>
                </span>
            </li>
        )
    }
    render () {
        const { isLoading, docs, isAdmin } = this.state;

        if (isLoading || !isAdmin) return <Loader />;

        return (
            <div className="admin">
                <ul>
                    { docs.map((doc, i) => this.renderDoc(doc, i)) }
                </ul>
            </div>
        )
    }
}

export default Admin;
