import React from 'react';

import './SessionDetails.scss';

export default class SessionDetails extends React.Component {
    renderSpeakers() {
        const { activeSession } = this.props;
        const {
                value: {
                    speakers
                }
        } = activeSession;

        return (
            <>
                { speakers.map((speaker, index) => (
                    <div className="speaker" key={ `${speaker.value.name}${index}` }>
                        {speaker.value.picture &&
                            <img src={ `${process.env.REACT_APP_API}${speaker.value.picture.large.src}` } alt={ speaker.value.picture.title } />
                        }
                        <div>
                            <p className="speaker-name">
                                { speaker.value.name }
                                { speaker.value.linked_in &&
                                    <a className="linkedin" href={ speaker.value.linked_in } target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"><path fill="#1A3C42" d="M15.3,0 L0.7,0 C0.3,0 0,0.3 0,0.7 L0,15.4 C0,15.7 0.3,16 0.7,16 L15.4,16 C15.8,16 16.1,15.7 16.1,15.3 L16.1,0.7 C16,0.3 15.7,0 15.3,0 Z M4.7,13.6 L2.4,13.6 L2.4,6 L4.8,6 L4.8,13.6 L4.7,13.6 Z M3.6,5 C2.8,5 2.2,4.3 2.2,3.6 C2.2,2.8 2.8,2.2 3.6,2.2 C4.4,2.2 5,2.8 5,3.6 C4.9,4.3 4.3,5 3.6,5 Z M13.6,13.6 L11.2,13.6 L11.2,9.9 C11.2,9 11.2,7.9 10,7.9 C8.8,7.9 8.6,8.9 8.6,9.9 L8.6,13.7 L6.2,13.7 L6.2,6 L8.5,6 L8.5,7 L8.5,7 C8.8,6.4 9.6,5.8 10.7,5.8 C13.1,5.8 13.5,7.4 13.5,9.4 L13.5,13.6 L13.6,13.6 Z"/></svg>
                                    </a>
                                }
                            </p>
                            { speaker.value.title &&
                                <p>
                                    {speaker.value.title}, {speaker.value.company}
                                </p>
                            }
                        </div>                        
                    </div>
                ))}
            </>
        )

    }

    renderSessionDetails() {
        const { activeSession } = this.props;

        const {
                value: {
                    speakers,
                }
        } = activeSession;

        return (
            <div className="speakers">
                { speakers.length > 0 && this.renderSpeakers() }
            </div>
        )
    }
    render () {
        const { activeSession, areDetailsActive } = this.props;
        
        return (
            <div className={`sessionDetails ${areDetailsActive && 'sessionDetails-isActive'}`}>
                <h4>Speakers:</h4>
                { activeSession !== null && this.renderSessionDetails() }
            </div>
        )
    }
}
