import React, { Component } from 'react';
import './Loader.scss';

class Loader extends Component {

	render() {
        const { children } = this.props;

		return (
	  		<div className="loader">
                <svg className="loaderLogo" id="eR5zDWycnKU1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.4 85.3" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><g><path className="path" d="M61.9,2.5c-.4-.8-1.1-1.4-1.8-1.8s-1.6-.7-2.5-.7-1.7.2-2.5.7c-.7.4-1.4,1.1-1.8,1.8l-4.9,8.8-5.1-8.7c-.4-.8-1.1-1.4-1.8-1.8-.7-.6-1.5-.8-2.4-.8s-1.7.2-2.5.7-1.4,1.1-1.8,1.8L0.6,61.7c-.4.8-.6,1.6-.6,2.5s.2,1.7.6,2.5c.4.7,1.1,1.4,1.8,1.8.8.4,1.6.7,2.5.7h10.1l-5,8.6c-.4.8-.6,1.6-.6,2.5s.2,1.7.6,2.5s1.1,1.4,1.8,1.8c.8.4,1.6.7,2.5.7h68.1c.9,0,1.7-.2,2.5-.7s1.4-1.1,1.8-1.8.7-1.6.7-2.5-.2-1.7-.7-2.5l-5-8.7h10.7c.9,0,1.7-.2,2.5-.7.7-.4,1.4-1.1,1.8-1.8.4-.8.7-1.6.7-2.5s-.2-1.7-.6-2.5L61.9,2.5ZM7.1,62.8L39,7.6l22.5,39h-11.4L41.6,32c-.3-.5-.7-.9-1.2-1.2s-1-.4-1.6-.4-1.1.1-1.6.4-.9.7-1.2,1.2L18.4,62.8h-11.3Zm9.3,16.3l6.5-11.3.2-.3L39,40.2L44.7,50L36.3,64.6c-.3.5-.4,1-.4,1.6s.1,1.1.4,1.6.7.9,1.2,1.1c.5.3,1,.4,1.6.4h35.2L80,79.1h-63.6ZM57.6,63h-13.2L50,53.2h16.9c.6,0,1.1-.1,1.6-.4s.9-.7,1.2-1.1c.3-.5.4-1,.4-1.6c0-.5-.1-1.1-.4-1.6L51.8,17.7l5.7-9.9L89.7,63h-32.1Z" fill="#1a3c42"/></g></svg>
                { children }
			</div>
	    );
	}
}

export default Loader;
