import React, { Component } from 'react';
import './Navbar.scss';
import { Link } from 'react-router-dom';

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navbarScrolled: false,
            navbarDark: false,
            mobileNavActive: false
        }

        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const { navbarScrolled } = this.state;

        if(window.scrollY > 0 && !navbarScrolled) {
            this.setState({
                navbarScrolled: true
            });
        } else if (window.scrollY === 0 && navbarScrolled) {
            this.setState({
                navbarScrolled: false
            });
        }
    }

	render() {
        const { navbarScrolled } = this.state;
        const { handleLogout } = this.props;

		return (
            <nav className={`navbar ${navbarScrolled && 'navbarDark'}`}>
                <div className="navbar-container">
                    <Link to="/">
                        <img src="/assets/images/canute_logo_city_black.svg" alt="Canute Logo"/>
                    </Link>
                    <button onClick={ handleLogout }>
                        <img src="/assets/images/Icon-sign-out.svg" alt="Sign out icon" />
                    </button>
                </div>
            </nav>
		)
	}
}

export default Navbar;
