import React, { useState } from "react";
import AddToCalendar from 'react-add-to-calendar';
import './AddToCalendar.scss';

const formatForGoogle = (time) => {
    if (!time) return null;

    return time.replaceAll('-', '').replaceAll(':', '');
}

const AddToCalendarComponent = ({ event, timezone }) => {
    const [show, toggleShow] = useState(false);
    const { title, startTime, endTime } = event;


    const google = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${title}&dates=${formatForGoogle(startTime)}/${formatForGoogle(endTime)}&ctz=${timezone}`;

    const outlook = `http://outlook.live.com/owa/?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startTime}&enddt=${endTime}&subject=${title}`;

    return (
        <div className="AddToCalendar">
            { show && (
                <div>
                    <a href={google} target="_blank" rel="noopener noreferrer"><img src="/assets/images/google.svg" /></a>
                    <a href={outlook} target="_blank" rel="noopener noreferrer"><img src="/assets/images/outlook.svg" /></a>
                    <AddToCalendar 
                        event={event}
                        buttonLabel=''
                        listItems={ [ { apple: '' } ] }
                        optionsOpen
                    />
                </div>
            )}
            <button onClick={() => toggleShow(!show)}><img src="/assets/images/calendar.svg" /></button>
        </div>
    );
}

export default AddToCalendarComponent;