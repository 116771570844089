import React, { Component } from 'react';
import { requestPlaybook } from '../../Request.js'
import { getDay, getDate, getMonth, getYear, getNextDay } from '../../helpers/date.js';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { ToastContainer, toast } from 'react-toastify';

import './Programmes.scss'

import Header from '../../components/Header/Header';
import Day from '../../components/Day/Day';
import Loader from '../../components/Loader/Loader';
import Categories from '../../components/Categories/Categories';
import Contacts from '../../components/Contacts/Contacts';

export default class Programmes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            activeDay: null,
            isKeyActive: false,
            isSignedIn: false,
            isLoading: true,
            name: '',
            email: '',
            password: '',
            emailValid: false,
            continueWithEmail: false,
            continueWithRegistration: false,
            emailExists: false,
            forgotPassword: false,
            resetEmailLoading: false,
            categories: []
        }


        this.onGoogleSignInClick = this.onGoogleSignInClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleContiueWithEmail = this.handleContiueWithEmail.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handleKeyClick = this.handleKeyClick.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
    }

    componentDidMount() {
        const { match: { params: { id } }, history: { push } } = this.props;

        requestPlaybook(id).then((data) => {
            const { items } = data;

            if (items.length) {
                const speakers = [];
                items.forEach(i => {
                    i.day.forEach(day => {
                        day.value.sessions.forEach(session => {
                            session.value.speakers.forEach(speaker => {
                                const name = speaker.value.name === '' ? 'No name' : speaker.value.name;

                                if (!speakers.filter((s) => name.includes(s.name)).length || name === 'No name') {
                                    const speakerWithSession = {
                                        ...speaker.value,
                                        name,
                                        sessionTitle: session.value.title
                                    };
                                    speakers.push(speakerWithSession);
                                }
                            })
                        })
                    })
                })
                
                const startupMembers = [];
                items[0].startups.forEach(startup => {
                    startup.value.members.map((member) => {
                        return startupMembers.push({
                            ...startup.value,
                            ...member
                        });
                    });
                });

                const categories = items[0].day.map(day => day.value.sessions.map(session => session.value.category.map(category => category))).flat(Infinity).filter((value, index, array) => array.indexOf(value) === index);

                this.setState({
                    data: items[0],
                    activeDay: items[0].day[0].value.title,
                    speakers: speakers,
                    startups: startupMembers,
                    categories
                });
            } else {
                push('not-found');
            }
        });
    }

    validateEmail(value) {
        return String(value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    handleEmailChange(e) {
        const { target: { value } } = e;

        this.setState({
            email: value,
            emailValid: this.validateEmail(value)
        })
    }

    handlePasswordChange(e) {
        const { target: { value } } = e;

        this.setState({
            password: value
        })
    }

    handleContiueWithEmail = async() => {
        const { continueWithRegistration, continueWithEmail, password, email} = this.state;

        if (!continueWithRegistration && !continueWithEmail) {
            const db = firebase.firestore();
            const query = await db.collection('usersCollection').where('email', '==', email).get();
            if (query.docs.length === 0) {
                this.setState({
                    continueWithRegistration: true
                });
            } else {
                this.setState({
                    continueWithEmail: true
                })
            };
            return;
        };

        if (continueWithRegistration) {
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then(() => null)
                .catch((e) => {
                    console.log(e);
                    
                    if (e.code === "auth/email-already-in-use") {
                        toast.error('An account with this email address already exists, please sign in.')
                        this.setState({
                            continueWithEmail: true,
                            continueWithRegistration: false
                        });
                    } else {
                        toast.error(e.message)
                    }
                });
            return;
        }

        if (continueWithEmail) {
            firebase.auth().signInWithEmailAndPassword(email, password)
                .then(() => null)
                .catch((e) => {
                    if (e.code === "auth/wrong-password") {
                        toast.error('Your email or password is incorrect, please try again.')
                    } else if (e.code === "auth/user-not-found") {
                        toast.error('An account with this email address was not found. Please try a different sign in method.')
                    }
                });
            return;
        }
    }

    handleNameChange(e) {
        const { target: { value } } = e;
        this.setState({
            name: value
        })
    }

    onGoogleSignInClick() {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)
    }


    handleDayClick(day) {
        this.setState({
            activeDay: day,
            signIn: day === 'contacts'
        })
    }

    handleKeyClick() {
        this.setState(prevState => ({
            isKeyActive: !prevState.isKeyActive
        }))
    }

    handleDate(i) {
        const { data: { date_from, date_to, day } } = this.state;

        if (i === 0) {
            return `${getDay(date_from)}, ${getMonth(date_from)} ${getDate(date_from)}, ${getYear(date_from)}`;
        } else if (i > 0 && i < day.length - 1) {
            const nextDay = getNextDay(date_from , i);
            return `${getDay(nextDay)}, ${getMonth(nextDay)} ${getDate(nextDay)}, ${getYear(nextDay)}`;
        } else if (i === day.length - 1) {
            return `${getDay(date_to)}, ${getMonth(date_to)} ${getDate(date_to)}, ${getYear(date_to)}`;
        } else {
            return '';
        }
    }

    handleResetPassword() {
        const { email } = this.state;

        this.setState({
            resetEmailLoading: true
        });

        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                toast.success('Password reset email sent!')
            })
            .catch((error) => {
                toast.error('We were unfortunately not able to send a password reset email. Please try again')
            }).finally(() => {
                this.setState({
                    resetEmailLoading: false
                });
            });
    } 

    render() {
        const { data, activeDay, isKeyActive, speakers, startups, signIn, emailValid, continueWithEmail, continueWithRegistration, resetEmailLoading, categories } = this.state;
        const { match: { params: { id } }, isSignedIn, userRole } = this.props;
        
        if (!data) return <Loader />;

        const { day } = data;

        return (
            <div className={ activeDay === 'contacts' ? 'contacts' : '' }>
                <Header data={ data } isKeyActive={ isKeyActive }/>
                <div className="content">
                    <div className="tabs">
                        { day.map((item, i) => (
                            <button key={ `${item.value.title}button`} className={`tabItem ${activeDay === item.value.title && 'tabItem-isActive'}`} onClick={ () => this.handleDayClick(item.value.title) }>{ item.value.title }<p>{this.handleDate(i)}</p></button>
                        ))}
                        <button className={`tabItem contacts ${activeDay === 'contacts' && 'tabItem-isActive'}`} onClick={ () => this.handleDayClick('contacts') }>Contacts</button>
                    </div>
                    <Categories isKeyActive={ isKeyActive } categories={categories} />
                    { day.map((item) => (
                        <React.Fragment key={ `${item.value.title}day`}>
                            { item.value.title === activeDay &&
                                <Day day={ item } id={id} />
                            }
                        </React.Fragment>
                    ))}
                    { activeDay === 'contacts' && (
                        <>   
                            { !isSignedIn && signIn &&
                                <div className="authContainer">
                                    <div className="authContent">
                                        <div className="authContainer-left">
                                            <img className="canuteLogo authContainer-logo" src="/assets/images/canute_logo_city_black.svg" width="132px" alt="Canute Logo"/>
                                            { !continueWithRegistration && <h1>Please <strong>Sign in</strong> below to view the content of our upcoming programs.</h1> }
                                            { continueWithRegistration && <h1>Please <strong>Register</strong> below to view the content of our upcoming programs.</h1> }
                                            <input className="authContainer-email" type="email" placeholder="E-mail" onChange={this.handleEmailChange} />
                                            { continueWithEmail &&
                                                <>
                                                    <input className="authContainer-password" type="password" placeholder="Password" onChange={ this.handlePasswordChange }/>
                                                    <div className="authContainer-resetPasswordContainer">
                                                        { !resetEmailLoading && <button className="authContainer-resetPassword" onClick={this.handleResetPassword}>Forgot your password?</button> }
                                                        { resetEmailLoading && <div className="stage"><div class="dot-flashing"></div></div> }
                                                    </div> 
                                                </>
                                            }
                                            { continueWithRegistration && (
                                                <>
                                                    <input className="authContainer-name" type="text" placeholder="First & Last Name" onChange={ this.handleNameChange }/>
                                                    <input className="authContainer-password" type="password" placeholder="Password" onChange={ this.handlePasswordChange }/>
                                                </>
                                            )}
                                            <div className="authContainer-signInActions">
                                                <button 
                                                    className="authContainer-signInButton authContainer-signInButton_email" 
                                                    disabled={!emailValid}
                                                    onClick={this.handleContiueWithEmail}
                                                >{continueWithEmail ? 'Sign in with e-mail' : 'Continue sign in with e-mail'}</button>
                                                <p>Or</p>
                                                <button className="authContainer-signInButton authContainer-signInButton_google" onClick={ this.onGoogleSignInClick }><img src="/assets/images/google-color.svg" alt="Google icon" />Sign in with Google</button>
                                            </div>
                                            <p>By continuing, you are indicating that you accept our Terms of Service and Privacy Policy</p>
                                        </div>
                                        <div className="authContainer-right">
                                            <img src="/assets/images/auth-image.jpg" alt="London skyline" />
                                        </div>
                                        <button className="authContainer-close" onClick={() => this.setState({ signIn: false })}>X</button>
                                    </div>
                                </div>
                            }
                            {isSignedIn ?
                                <Contacts speakers={ speakers } startups={ startups } userRole={userRole} title={data.title} />
                                :
                                <button className="authContainer-signInButton authContainer-signInButton_button" onClick={() => this.setState({ signIn: true })}>Please sign in</button>
                            }
                        </>
                    )}
                </div>
            </div>
        )
    }
}
