import React from 'react';
import './CityCard.scss';
import { Link } from 'react-router-dom';
import ProgramDate from '../ProgramDate/ProgramDate';

export default function CityCard(props) {
    const {
        playbook: {
            meta: { slug },
            playbook_title,
            playbook_header_image: { title, meta: { download_url }},
            date_from,
            date_to
        }
    } = props;

    return (
        <Link to={ `/${slug}` } className="cityCard">
            <div className="cityCard">
                <div className="cityCard-cityCardImage">   
                    <img className="cityCard-cityCardImageImage" src={ `${process.env.REACT_APP_API}${download_url}` } alt={ title } />
                    <p className="cityCard-cityCardCity">{ playbook_title } <img src="/assets/images/white-arrow-right.svg" alt="Arrow icon" /></p>
                </div>
                <ProgramDate dateFrom={date_from} dateTo={date_to} />
            </div>
        </Link>
    )
}