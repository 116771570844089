import React from 'react';
import { getMonth, getYear, englishOrdinalSuffix } from '../../helpers/date.js';
import './ProgramDate.scss';

const ProgramDate = ({dateFrom, dateTo}) => {
    return (
        <p className="ProgramDate"><span>Program date: </span>{ englishOrdinalSuffix(new Date(dateFrom)) } -  { englishOrdinalSuffix(new Date(dateTo)) } { getMonth(dateTo) }  { getYear(dateTo) }</p>
    )
}

export default ProgramDate;