import React, { Component } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import Programmes from './views/Programmes/Programmes';
import NotFound from './views/NotFound/NotFound';
import Home from './views/Home/Home';
import Admin from './views/Admin/Admin';

import Navbar from './components/Navbar/Navbar';
import Loader from './components/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSignedIn: false,
            isLoading: true,
            name: '',
            email: '',
            password: '',
            emailValid: false,
            continueWithEmail: false,
            continueWithRegistration: false,
            emailExists: false
        }

        this.handleLogout = this.handleLogout.bind(this);
    }
    componentDidMount() {
        // Your web app's Firebase configuration
        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_DATABASE_URL,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APP_ID,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID
        };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);

        var db = firebase.firestore();

        let that = this;
        firebase.auth().onAuthStateChanged(function(user) {
            that.setState({
                isLoading: true
            });

            if (user) {
                // User is signed in.
                const docRef = db.collection("usersCollection").doc(user.uid);

                docRef.get().then(function(doc) {
                    if (!doc.exists) {
                        const usersRef = db.collection("usersCollection");
                        usersRef.doc(user.uid).set({
                            email: user.email,
                            name: user.displayName,
                            role: 'general'
                        });
                        that.setState({
                            userRole: 'general'
                        })
                    } else {
                        that.setState({
                            userRole: doc.data().role
                        })
                    }
                })

                that.setState({
                    isSignedIn: true,
                    isLoading: false
                });
            } else {
                // User is signed out.
                that.setState({
                    isLoading: false
                });
            }
        }, function(error) {
            console.log(error);
            that.setState({
                isLoading: false
            });
        });
    }

    handleLogout() {
        let that = this;
        firebase.auth().signOut().then(() => {
            that.setState({
                isSignedIn: false,
                isLoading: false
            });

          });
    }

    render() {
        const { isSignedIn, isLoading, userRole } = this.state;

        return (
          <div className="App">
              <ToastContainer
                autoClose={ false }
              />
              { !isSignedIn && isLoading &&
                  <Loader />
              }
            <Router>
                <Navbar handleLogout={this.handleLogout} />
                <div className="container">
                <Switch>
                    <Route path="/" exact component={ Home } />
                    <Route path="/admin" exact component={(props) => <Admin {...props} userRole={userRole} />} />
                    <Route path="/not-found" exact component={ NotFound } />
                    <Route path="/:id" exact component={(props) => <Programmes {...props} isSignedIn={isSignedIn} userRole={userRole} />} />
                </Switch>
                </div>
            </Router>
          </div>
        );
    }

}

export default App;
