import React, { Component } from 'react';

import Loader from '../../components/Loader/Loader';

export default class NotFound extends Component {
    render () {
        return (
            <div className="NotFound">
                <Loader>
                    <h1>Oops, we seem to have lost our way...</h1>
                </Loader>
            </div>
        )
    }
}
